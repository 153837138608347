import React, { useState, useEffect, Fragment, useRef } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useNavigate, Redirect, useLocation, useParams } from "react-router";
import { useForm } from "react-hook-form";
import './index.scss';

function PrivacyPolicy() {
  const navigate = useNavigate();
  const location = useLocation();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      // behavior: "smooth"
    });
  }, [])

  return (
    <div className="Home-page">
      <div className='page-body-section' id="aboutus" style={{ background: '#f8f9fa', position: 'relative' }}>
        <div className="container">
          <div className="row" style={{ justifyContent: 'center', alignItems: 'center' }}>
            <div className={`col-md-10`}>
              <div className='p-about-text'>
                <div className='heading'>
                  <span className='highlighted-heading' style={{ fontSize: '2rem' }}>Privacy policy</span>
                </div>
                <p className='text'>we are updating soon our privacy policy.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

export default PrivacyPolicy;
