import React, { useState } from 'react';
import { BrowserRouter as Router, Link } from "react-router";
import "antd/dist/antd.min.css";
import './index.scss';
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from "react-redux";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIcon from '@mui/icons-material/Phone';
import Button from '@mui/joy/Button';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

function Footer() {

  return (
    <div className="Footer-page" style={{ position: 'absolute', bottom: '24px', width: '100%', height: '0rem' }}>
      <br />
      <div className="Footer-menu-card1">
        <div className="container">
          <div className="Footer-menu-card fp-mp" style={{ paddingBottom: '40px' }}>
            <div className="Footer-menu-item">
              <div className="Header-menu-logoName" style={{ paddingBottom: '10px', marginBottom: '10px', justifyContent: 'center' }}>
                <Link to={`/`} style={{ position: 'relative' }}>
                  <svg style={{ left: '-10px', top: '-5px' }} width="30px" viewBox="0 0 38.754 33.794">
                    <g id="Group_9465" data-name="Group 9465" transform="translate(-1085.103 -2866.076)">
                      <path id="Path_382" data-name="Path 382" d="M11034.407,2887.555a2.567,2.567,0,0,1,2.8,1.683,2.627,2.627,0,0,1-1.44,3.34c-.571.251-27.506,7.345-27.506,7.345a2.082,2.082,0,0,1-1.494-.671c-.625-.7-4.624-8.342-4.624-8.342l2.477-.7,4.206,4.206,8.762-2.343-7.727-12.17,3.327-.827,11.864,10.968Z" transform="translate(-9915 -11.459)" fill="none" stroke="#636a70" stroke-width="2.8" />
                    </g>
                  </svg>
                  <div style={{ lineHeight: 0.9, fontSize: '1.2rem', textTransform: 'uppercase', textAlign: 'right' }}>
                    <span className='name'><i><strong>seven</strong></i></span><br />
                    <span className='slug'><i><strong>tourism</strong></i></span></div>
                </Link>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <a href='https://www.facebook.com/seventourismofficial/' target='_blank'>
                  <FacebookOutlinedIcon className='fp-social-icon' /></a>
                <a href='https://www.instagram.com/seventourism/' target='_blank'>
                  <InstagramIcon className='fp-social-icon' style={{ margin: '0px 10px' }} /></a>
                <a href='https://www.youtube.com/@seventourism' target='_blank'>
                  <YouTubeIcon className='fp-social-icon' /></a>
                <a href='https://www.linkedin.com/company/seventourism/' target='_blank'>
                  <LinkedInIcon className='fp-social-icon' style={{ margin: '0px 10px' }} /></a>
              </div>
            </div>

            <div className="Footer-menu-item">
              <div style={{ fontSize: '1rem', fontWeight: 600, padding: '5px' }}>Links</div>
              <div className="Footer-menu-SubItem"><Link to="/privacy-policy">Privacy Policy</Link></div>
              <div className="Footer-menu-SubItem"><Link to="/terms-and-conditions">Terms & Conditions</Link></div>
            </div>
            <div className="Footer-menu-item">
              <div style={{ fontSize: '1rem', fontWeight: 600, padding: '5px' }}>About Us</div>
              <div className="Footer-menu-SubItem"><Link to="/#aboutus">About</Link></div>
              <div className="Footer-menu-SubItem"><Link to="/#services">Services</Link></div>
            </div>


            <div className="Footer-menu-item">
              <div style={{ fontSize: '1rem', fontWeight: 600, padding: '5px' }}>Address</div>
              <div className="Footer-menu-SubItem">
                <div style={{ lineHeight: 1.5 }}>
                  <div>Delhi, India.</div>
                  <p>
                    <MailOutlineIcon style={{ fontSize: '22px', color: '#3e3f40' }} />
                    <span style={{ margin: '0px 10px' }}>support@seventourism.com</span>
                    <br />
                    <PhoneIcon style={{ fontSize: '22px', color: '#3e3f40' }} />
                    <span style={{ margin: '0px 10px' }}>+91 99908 28161</span>
                  </p>
                </div>
              </div>
            </div>


          </div>
          <div className="Footer-menu-card" style={{ borderTop: '1px solid #eee' }}>
            <div className="Footer-menu-item Footer-menu-bottom" style={{ justifyContent: 'space-between' }}>
              <div className='Footer-menu-subcard'>
                <div className="Footer-menu-SubItem">
                  © {new Date().getFullYear()} Seven Tourism. All rights reserved
                </div>
              </div>
              <div className='Footer-menu-subcard'>
                <div className="Footer-menu-SubItem">
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  </div>
                </div>
                <div className="Footer-menu-SubItem">
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
