import React, { Component } from 'react';
import { Redirect, useNavigate } from "react-router";
import './index.scss';
import IconButton from '@mui/joy/IconButton';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

function NotFound() {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="tabel_cards">
      <div className="row card-list-row">
        <div className="col-md-12">
          <div className="updateButton">
            <div className='overflow' style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {/* <IconButton
                  style={{ minHeight: '27px', maxHeight: '27px' }}
                  variant='outlined'
                  size='sm'
                  onClick={handleBack}
                >
                  <ArrowBackOutlinedIcon style={{ fontSize: '1.2rem' }} />
                </IconButton> */}
                {/* <div className="page-not-found" style={{ margin: '0px 10px' }}>
                  <div className='text alert alert-danger'>Page Not Found</div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
