import React, { useState } from 'react';
import './index.scss';
import { ParallaxProvider } from 'react-scroll-parallax';
import Header from '../web/Components/Header';
import Footer from '../web/Pages/Footer';
function WebPage(props) {
  const Components = props.component;

  const [collapsed, setStatesss] = useState(window.innerWidth > 992 ? false : true)
  function toggleCollapsed() {
    setStatesss(!collapsed)
  };

  return (
    <ParallaxProvider>
      <div className="page-section">
        <div className={`page-sub-section`} style={{ textAlign: 'left', fontFamily: 'Poppins, sans-serif', display: 'block', minHeight: '100vh', position: 'relative', margin: 0, padding: 0 }}>
          <Header />
          <Components />
          <Footer />
        </div>
      </div>
    </ParallaxProvider>
  );
}

export default WebPage;
