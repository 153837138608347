import React, { useState, useEffect, Fragment, useRef } from 'react';
import './index.scss';
import Button from '@mui/joy/Button';

function Contactus() {

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      // behavior: "smooth"
    });
  }, [])


  return (
    <div className="Home-page">

      <div className='page-body-section' style={{ background: '#f8f9fa', position: 'relative', padding: 0 }}>
        <div className="row" style={{ justifyContent: 'center' }}>
          <div className={`col-md-7 pg-title-section`}>
            <div style={{ position: 'relative' }}>
              <h1 className='pb-title'>We're Always Glad to Help You!</h1>
            </div>
          </div>
        </div>
      </div>

      <div className='section' style={{ background: '#fff', position: 'relative' }}>
        <div className="container">
          <div className="row" style={{ justifyContent: 'center' }}>
            <div className={`col-md-3`}>
              <div style={{ textAlign: 'center' }}>
                <div style={{ fontSize: '3rem' }}>
                  <svg width="55px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-shield">
                    <path d="M12 2l9 4.5v5c0 5.6-3.8 10.74-9 12-5.2-1.26-9-6.4-9-12v-5L12 2z"></path>
                    <polyline points="9 12 12 15 15 10"></polyline>
                  </svg>
                </div>
                <div style={{ fontSize: '1.5rem', padding: '10px' }}>Trust</div>
              </div>
            </div>
            <div className={`col-md-3`}>
              <div style={{ textAlign: 'center' }}>
                <div style={{ fontSize: '3rem' }}>💡</div>
                <div style={{ padding: '10px', fontSize: '1.5rem' }}>Expertise</div>
              </div>
            </div>
            <div className={`col-md-3`}>
              <div style={{ textAlign: 'center' }}>
                <div style={{ fontSize: '3rem' }}>✔️</div>
                <div style={{ padding: '10px', fontSize: '1.5rem' }}>Reliability</div>
              </div>
            </div>
            <div className={`col-md-3`}>
              <div style={{ textAlign: 'center' }}>
                <div style={{ fontSize: '3rem' }}>🌟</div>
                <div style={{ padding: '10px', fontSize: '1.5rem' }}>Commitment </div>
              </div>
            </div>
          </div>
        </div>
      </div>



      <div className='page-body-section' style={{ background: '#fff', position: 'relative' }}>
        <div className="container">
          <div className="row" style={{ justifyContent: 'center' }}>
            <div className={`col-md-7 pg-form-section`}>
              <div style={{ position: 'relative', width: '100%' }}>
                <h1 style={{ fontWeight: 600, fontSize: '2.5rem', marginBottom: '20px' }}>Get in touch</h1>
                <form>
                  <div className='form-group' style={{ marginBottom: '2rem', marginTop: '1rem' }}>
                    <input className='form-control' placeholder='Your Name' style={{ background: '#f8f9fa', border: '1px solid #e2e2e2', color: '#000', padding: '1rem', height: '60px' }} />
                  </div>
                  <div className='form-group' style={{ marginBottom: '2rem', marginTop: '1rem' }}>
                    <input className='form-control' placeholder='Your Email' style={{ background: '#f8f9fa', border: '1px solid #e2e2e2', color: '#000', padding: '1rem', height: '60px' }} />
                  </div>
                  <div className='form-group' style={{ marginBottom: '2rem', marginTop: '1rem' }}>
                    <textarea className='form-control' placeholder='Message' style={{ background: '#f8f9fa', border: '1px solid #e2e2e2', color: '#000', padding: '1rem' }}>
                    </textarea>
                  </div>
                  <div className='form-group'>
                    <Button variant='outlined' size='lg'>Submit</Button>
                  </div>
                </form>
              </div>
            </div>

          </div>
        </div>
      </div>



    </div>

  );
}

export default Contactus;
