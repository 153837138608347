import React, { useState, useEffect, Fragment, useRef } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useNavigate, Redirect, useLocation, useParams } from "react-router";
import { useForm } from "react-hook-form";

import './index.scss';
import Button from '@mui/joy/Button';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIcon from '@mui/icons-material/Phone';

function Home() {
  const navigate = useNavigate();
  const location = useLocation();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      // behavior: "smooth"
    });
  }, [])


  useEffect(() => {
    const sectionId = location.hash;
    if (sectionId) {
      const element = document.querySelector(sectionId);
      if (element) {
        window.scrollTo({
          top: element.offsetTop,
          behavior: 'smooth',
        });
      }
    }
  }, [location, location.hash]);


  return (
    <div className="Home-page">

      <div className="inner-page-section" id='home'>
        <div className="container" style={{ padding: '0px' }}>
          <div className="row" style={{ justifyContent: 'center' }}>
            <div className={`col-md-8`}>
              <div style={{ position: 'relative', display: 'block', lineHeight: 1, textAlign: 'center' }}>
                <h1 style={{ display: 'none' }}>Seven Tourism: Your Gateway to Amazing Travel Experiences</h1>
                <h1 className='form-title' style={{ textAlign: 'center', position: 'relative' }}>
                  <span>Your gateway to the world and beyond.</span>
                </h1>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>
                  <div style={{ padding: '0px 10px 0px 10px', fontSize: '1.2rem' }}>Holiday's</div>
                  <div style={{ padding: '0px 10px 0px 10px', fontSize: '1.2rem' }}>Tickets</div>
                  <div style={{ padding: '0px 10px 0px 10px', fontSize: '1.2rem' }}>Visa's</div>
                  <div style={{ padding: '0px 10px 0px 10px', fontSize: '1.2rem' }}>Umrah </div>
                </div>
                <Button
                  style={{ borderRadius: '100px', marginTop: '15px' }}
                  component={Link}
                  to={`/book-now`}
                  variant='outlined'
                  size='lg'
                >
                  <span style={{ color: '#3e3f40', margin: '0px 5px' }}>Book Now</span>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="inner-page-section" style={{ padding: 0 }}>
        <div className="row">
          <div className={`col-md-3 image-container`} style={{ padding: 0, position: 'relative' }}>
            <img
              alt={'seven-tourism-umrah'}
              src={`/assets/images/seven-umrah-01.webp`}
              style={{ transition: "all 0.5s", width: '100%', height: '100%', objectFit: 'cover' }}
            />
            <div className='pg-images-overlay'>
              <span>Umrah</span>
            </div>
          </div>
          <div className={`col-md-3 image-container`} style={{ padding: 0 }}>
            <img
              alt={'seven-tourism-Dubai City Tour'}
              src={`/assets/images/seven-tourism-08.jpg`}
              style={{ transition: "all 0.5s", width: '100%', height: '100%', objectFit: 'cover' }}
            />
            <div className='pg-images-overlay'>Dubai City Tour</div>
          </div>
          <div className={`col-md-3 image-container`} style={{ padding: 0 }}>
            <img
              alt={'seven-tourism-Dubai Desert Safari '}
              src={`/assets/images/seven-tourism-02.jpg`}
              style={{ transition: "all 0.5s", width: '100%', height: '100%', objectFit: 'cover' }}
            />
            <div className='pg-images-overlay'>Dubai Desert Safari</div>
          </div>
          <div className={`col-md-3 image-container`} style={{ padding: 0 }}>
            <img
              alt={'seven-tourism-Kashmir Tour'}
              src={`/assets/images/seven-tourism-05.jpg`}
              style={{ transition: "all 0.5s", width: '100%', height: '100%', objectFit: 'cover' }}
            />
            <div className='pg-images-overlay'>Kashmir Tour</div>
          </div>
        </div>
      </div>


      <div className='section' style={{ background: '#fff', position: 'relative' }}>
        <div className="container">
          <div className="row" style={{ justifyContent: 'center' }}>
            <div className={`col-md-3`}>
              <div style={{ textAlign: 'center' }}>
                <div style={{ fontSize: '3rem' }}>
                  <svg width="55px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-shield">
                    <path d="M12 2l9 4.5v5c0 5.6-3.8 10.74-9 12-5.2-1.26-9-6.4-9-12v-5L12 2z"></path>
                    <polyline points="9 12 12 15 15 10"></polyline>
                  </svg>
                </div>
                <div style={{ fontSize: '1.5rem', padding: '10px' }}>Trust</div>
              </div>
            </div>
            <div className={`col-md-3`}>
              <div style={{ textAlign: 'center' }}>
                <div style={{ fontSize: '3rem' }}>💡</div>
                <div style={{ padding: '10px', fontSize: '1.5rem' }}>Expertise</div>
              </div>
            </div>
            <div className={`col-md-3`}>
              <div style={{ textAlign: 'center' }}>
                <div style={{ fontSize: '3rem' }}>✔️</div>
                <div style={{ padding: '10px', fontSize: '1.5rem' }}>Reliability</div>
              </div>
            </div>
            <div className={`col-md-3`}>
              <div style={{ textAlign: 'center' }}>
                <div style={{ fontSize: '3rem' }}>🌟</div>
                <div style={{ padding: '10px', fontSize: '1.5rem' }}>Commitment </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='page-body-section' id="aboutus" style={{ background: '#f8f9fa', position: 'relative' }}>
        <div className="container">
          <div className="row" style={{ justifyContent: 'center', alignItems: 'center' }}>
            <div className={`col-md-7`}>
              <div className='p-about-text'>
                <div className='heading'>
                  <span className='highlighted-heading'>About Us</span>
                </div>
                <p className='text'>Welcome to Seven Tourism, your trusted partner in travel and pilgrimage services. Our mission is to make your journeys seamless, memorable, and spiritually fulfilling.</p>
                <p className='text'>At Seven Tourism, we specialize in a wide range of services designed to meet the diverse needs of our clients:</p>
                <p className='text'>With years of experience, we pride ourselves on our commitment to excellence, customer satisfaction, and ethical service delivery. Whether you're planning an international trip or embarking on a sacred journey, Seven Tourism is here to guide you every step of the way.</p>
                <p className='text'>Let us help you create unforgettable travel experiences, where your comfort and peace of mind come first.</p>
              </div>
            </div>
            <div className={`col-md-5`}>
              <img className='hp-side-image' src='assets/images/seven-about-us.webp' />
            </div>
          </div>
        </div>
      </div>


      <div className='page-body-section' id="services" style={{ background: '#fff', position: 'relative' }}>
        <div className="container">

          <div className="row" style={{ justifyContent: 'center', marginBottom: '40px' }}>
            <div className={`col-md-10`}>
              <div style={{ fontSize: '2.5rem', textAlign: 'center', fontWeight: 500, padding: '20px' }}>
                <span className='highlighted-heading'>Services</span>
              </div>
              <p style={{ textAlign: 'center', fontSize: '1.4rem' }}>we are committed to making your travel and pilgrimage experiences smooth, enjoyable, and stress-free. We offer a comprehensive range of services tailored to meet your specific needs</p>
            </div>
          </div>

          <div className="row" style={{ justifyContent: 'center' }}>

            <div className={`col-md-6`} style={{ padding: '20px', marginBottom: '40px' }}>
              <div className='hp-service-card'>
                <svg viewBox="0 0 64 64" width="64" style={{ filter: 'grayscale(1)' }}>
                  <rect x="8" y="16" width="48" height="32" rx="6" ry="6" fill="#1A1F71" stroke="#0033A0" stroke-width="2" />
                  <text x="50%" y="50%" font-size="16" font-family="Arial, sans-serif" fill="white" text-anchor="middle" dy=".3em">VISA</text>
                  <circle cx="32" cy="32" r="30" fill="none" stroke="#0033A0" stroke-width="2" />
                </svg>
                <div style={{ padding: '10px', fontSize: '2rem', fontWeight: 500 }}>Visa Assistance</div>
                <p style={{ fontSize: '1.2rem', padding: '10px' }}>Navigating the visa application process can be overwhelming, but our experts are here to help. From document preparation to submission, we ensure your application is handled efficiently for a hassle-free approval process.</p>
              </div>
            </div>

            <div className={`col-md-6`} style={{ padding: '20px', marginBottom: '40px' }}>
              <div className='hp-service-card'>
                <svg viewBox="0 0 64 64" width="64" style={{ filter: 'grayscale(1)' }}>
                  <rect x="8" y="16" width="48" height="32" rx="6" ry="6" fill="#4CAF50" stroke="#388E3C" stroke-width="2" />
                  <line x1="8" y1="32" x2="56" y2="32" stroke="#388E3C" stroke-width="2" />
                  <text x="50%" y="50%" font-size="24" font-family="Arial, sans-serif" fill="#FFFFFF" text-anchor="middle" dy=".3em">?</text>
                  <circle cx="32" cy="32" r="30" fill="none" stroke="#388E3C" stroke-width="2" />
                </svg>
                <div style={{ padding: '10px', fontSize: '2rem', fontWeight: 500 }}>Ticket Assistance</div>
                <p style={{ fontSize: '1.2rem', padding: '10px' }}>Whether it’s domestic or international travel, we provide ticketing solutions at competitive rates. Our team works to secure the best routes and schedules, ensuring your journey is as convenient as possible.</p>
              </div>
            </div>

          </div>
        </div>
      </div>


      <div className='page-body-section' style={{ background: '#f8f9fa', position: 'relative' }}>
        <div className="container">
          <div className="row" style={{ justifyContent: 'center', alignItems: 'center' }}>
            <div className={`col-md-5`}>
              <img src='/assets/images/seven-tourism-017.jpg' className='hp-side-image' />
            </div>
            <div className={`col-md-7`}>
              <div className='p-about-text'>
                <div className='heading'>
                  <span className='highlighted-heading'>Holiday Assistance</span>
                </div>
                <p style={{ fontSize: '1.2rem', padding: '10px' }}>Looking for the perfect getaway? We design personalized holiday packages to suit your preferences, offering a mix of leisure, adventure, and relaxation. From accommodation to tours, we ensure your holiday is unforgettable.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='page-body-section' style={{ background: '#fff', position: 'relative' }}>
        <div className="container">
          <div className="row" style={{ justifyContent: 'center', alignItems: 'center' }}>
            <div className={`col-md-7`}>
              <div className='p-about-text'>
                <div className='heading'>
                  <span className='highlighted-heading'>Umrah Assistance</span>
                </div>
                <p style={{ fontSize: '1.2rem', padding: '10px' }}>Embark on your spiritual journey with confidence. Our Umrah packages include visa arrangements, flights, accommodation, and on-ground support in Saudi Arabia. We take care of every detail so you can focus on your pilgrimage.</p>
              </div>
            </div>
            <div className={`col-md-5`}>
              <img src='/assets/images/seven-umrah-01.webp' className='hp-side-image' />
            </div>
          </div>
        </div>
      </div>


      <div className='page-body-section' style={{ background: '#f8f9fa', position: 'relative' }}>
        <div className="container">
          <div className="row" style={{ justifyContent: 'center', marginBottom: '40px' }}>
            <div className={`col-md-12`} style={{ textAlign: 'center' }}>
              <div style={{ fontSize: '2.5rem', textAlign: 'center', fontWeight: 600, padding: '10px' }}>
                <span className='highlighted-heading'>Get in touch</span>
              </div>
              <p style={{ fontSize: '1.5rem' }}>We're Always Glad to Help You!</p>
            </div>
          </div>
          <div className="row" style={{ justifyContent: 'center' }}>
            <div className={`col-md-6`}>
              <div className="row" style={{ justifyContent: 'center' }}>
                <div className={`col-md-6`} style={{ padding: '10px' }}>
                  <div className='fp-contact-card'>
                    <h1 style={{ fontWeight: 600 }}><MailOutlineIcon style={{ fontSize: '3rem', color: '#000' }} /></h1>
                    <p style={{ fontSize: '1rem', fontWeight: 500 }}>support@seventourism.com</p>
                  </div>
                </div>
                <div className={`col-md-6`} style={{ padding: '10px' }}>
                  <div className='fp-contact-card'>
                    <h1 style={{ fontWeight: 600 }}><PhoneIcon style={{ fontSize: '3rem', color: '#000' }} /></h1>
                    <p style={{ fontSize: '1rem', fontWeight: 500 }}>+91 99908 28161</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

  );
}

export default Home;
