import React, { Component, useState } from 'react';
import { Navigate, Link } from "react-router";
// import HeaderMenu from '../components/HeaderMenu'
import './index.scss';
import { useSelector } from 'react-redux'
function Admin(props) {
  const { userId, userToken, userEmail, userName, isStatus, userRole, languageCode, isAdmin } = useSelector((state) => state.auth);
  const Components = props.component;

  const [collapsed, setStatesss] = useState(window.innerWidth > 992 ? false : true)
  function toggleCollapsed() {
    setStatesss(!collapsed)
  };

  return (
    <div className="page-section">
      {/* <HeaderMenu collapsed={collapsed} toggleCollapsed={toggleCollapsed} />
      <div className={`page-sub-section ${collapsed && 'body-collapsed'}`}>
        {(userToken && userId) ? <Components /> : <Navigate to="/admin/login"></Navigate>}
      </div> */}
    </div>
  );
}

export default Admin;
