import React from "react";
import { BrowserRouter as Router, Switch, Route, Routes } from "react-router";

import Admin from './Private/admin';
import NotFound from './NotFound';
import { useSelector } from 'react-redux'

import WebPage from "./Private/WebPage";
import Home from "./web/Pages/Home";
import Contactus from "./web/Pages/Contactus";
import BookNow from "./web/Pages/BookNow";
import TermAndConditions from "./web/Pages/TermAndConditions";
import PrivacyPolicy from "./web/Pages/PrivacyPolicy";

import './App.css';

function App() {
  const { userRole, adminAccess } = useSelector((state) => state.auth);
  function isAcceess(name) {
    const accessArray = adminAccess ? adminAccess?.access : [];
    if (accessArray.includes('full_access')) {
      return true;
    } else {
      return accessArray.includes(name);
    }
  }

  return (
    <div className="App">

      <Router>
        <Routes>
          {/* Public Routes */}
          <Route index element={<WebPage component={Home} />} />
          <Route path="/book-now" element={<WebPage component={BookNow} />} />
          <Route path="/contact" element={<WebPage component={Contactus} />} />
          <Route path="/terms-and-conditions" element={<WebPage component={TermAndConditions} />} />
          <Route path="/privacy-policy" element={<WebPage component={PrivacyPolicy} />} />
          <Route path="*" element={<WebPage component={NotFound} />} />

          {/* Admin Routes */}
          <Route path="/admin">
            {/* <Route index element={<Admin component={Dashboard} />} />
            <Route path="deliveries" element={<Admin component={Deliveries} />} />
            <Route path="countries" element={<Admin component={isAcceess('countries') ? Countries : NotFound} />} /> */}
            <Route path="*" element={<Admin component={NotFound} />} />
          </Route>
        </Routes>
      </Router>

    </div>
  );
}

export default App;
