import React, { useState, useEffect, Fragment, useRef } from 'react';
import './index.scss';
import Button from '@mui/joy/Button';

function BookNow() {

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      // behavior: "smooth"
    });
  }, [])


  return (
    <div className="Home-page">

      <div className='page-body-section' style={{ background: '#f8f9fa', position: 'relative', padding: 0 }}>
        <div className="row" style={{ justifyContent: 'center' }}>
          <div className={`col-md-7 pg-title-section`}>
            <div style={{ position: 'relative' }}>
              <h1 className='pb-title'>Start Your Journey – Book Your Trip Now</h1>
            </div>
          </div>
        </div>
      </div>



      <div className='page-body-section' style={{ background: '#fff', position: 'relative' }}>
        <div className="container">
          <div className="row" style={{ justifyContent: 'center' }}>
            <div className={`col-md-7 pg-form-section`}>
              <div style={{ position: 'relative', width: '100%' }}>
                <h1 style={{ fontWeight: 600, fontSize: '2.5rem', marginBottom: '20px' }}>Book Now</h1>
                <form>
                  <div className='form-group' style={{ marginBottom: '2rem', marginTop: '1rem' }}>
                    <input className='form-control' placeholder='Your Name' style={{ background: '#f8f9fa', border: '1px solid #e2e2e2', color: '#000', padding: '1rem', height: '60px' }} />
                  </div>
                  <div className='form-group' style={{ marginBottom: '2rem', marginTop: '1rem' }}>
                    <input className='form-control' placeholder='Your Email' style={{ background: '#f8f9fa', border: '1px solid #e2e2e2', color: '#000', padding: '1rem', height: '60px' }} />
                  </div>
                  <div className='form-group' style={{ marginBottom: '2rem', marginTop: '1rem' }}>
                    <select className='form-control' style={{ background: '#f8f9fa', border: '1px solid #e2e2e2', color: '#000', padding: '1rem', height: '60px' }}>
                      <option>UAE Visa</option>
                      <option>Saudi Visa</option>
                      <option>Umrah Package</option>
                      <option>UAE Tour</option>
                      <option>Kashmir Tour</option>
                    </select>
                  </div>
                  <div className='form-group' style={{ marginBottom: '2rem', marginTop: '1rem' }}>
                    <textarea className='form-control' placeholder='Message' style={{ background: '#f8f9fa', border: '1px solid #e2e2e2', color: '#000', padding: '1rem' }}>
                    </textarea>
                  </div>
                  <div className='form-group'>
                    <Button variant='outlined' size='lg'>Submit</Button>
                  </div>
                </form>
              </div>
            </div>

          </div>
        </div>
      </div>



    </div>

  );
}

export default BookNow;
